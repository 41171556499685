<template>
  <v-layout>
    <Header v-model="expandOnHover"></Header>

    <v-content>
      <v-container fluid class="page-wrapper">
        <router-view />
        <orderPopup />
      </v-container>
    </v-content>

    <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>

    <!-- <v-navigation-drawer app clipped v-model="Sidebar_drawer">
      dasdsadsadasdsad
    </v-navigation-drawer> -->

    <Customizer v-model="expandOnHover"></Customizer>

    <!-- <Footer></Footer>  -->
  </v-layout>
</template>

<script>
import orderPopup from "../../components/orderPopup.vue";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
// import Footer from './footer/Footer';
import Customizer from "./customizer/Customizer";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Layout",

  components: {
    Header,
    Sidebar,
    // Footer,
    Customizer,
    orderPopup,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    Sidebar_drawer: false,
  }),
  computed: {
    ...mapState({ Customizer_drawer: "app/Customizer_drawer" }),
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "app/SET_CUSTOMIZER_DRAWER",
    }),
  },
};
</script>

<style>
</style>