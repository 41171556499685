<template>
  <section class="order">
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          bottom
          color="#3e688a"
          fixed
          right
          dark
          v-bind="attrs"
          v-on="on"
          class="popbtn"
          >Arrange a Ride</v-btn
        >
      </template>

      <AddEditOrderComponent
        :cancelCallback="cancel"
        :saveCallback="save"
        :setMessageSnackbar="setMessageSnackbar"
        :key="new Date().getTime()"
      />
    </v-dialog>
    <v-snackbar
      v-model="messageOnScreenModal"
      absolute
      centered
      color="primary"
      elevation="24"
      style="z-index: 20001"
    >
      {{ messageOnScreenText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary pr-5 pl-5"
          dark
          v-bind="attrs"
          @click="messageOnScreenModal = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import AddEditOrderComponent from "@/components/data/AddEditOrderComponent";
import { mapGetters } from "vuex";

export default {
  name: "orderPopup",
  components: {
    AddEditOrderComponent,
  },
  data: () => ({
    dialog: false,
    //
    messageOnScreenModal: false,
    messageOnScreenText: "",
  }),
  computed: {
    ...mapGetters(["strapi"]),
  },
  methods: {
    setMessageSnackbar(text) {
      this.messageOnScreenModal = true;
      this.messageOnScreenText = text;
    },
    cancel() {
      this.dialog = false;
    },
    async save(order, type, processDates) {
      this.cancel();
      try {
        if (type === "add") {
          var newItem = await this.strapi.createEntry("orders", order);
          processDates(newItem);
        } else {
          var updatedItem = await this.strapi.updateEntry(
            "orders",
            parseInt(order.id),
            order
          );

          processDates(updatedItem);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  padding: 5px 25px 65px 25px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.popbtn {
  border-radius: 25px;
}
</style>
