export default [
  // { header: "Personal" },
  {
    // icon: 'mdi-file',
    title: "Dashboard",
    to: "/dashboard/view",
    roles: ["Admin"],
  },
  {
    // icon: 'mdi-file',
    title: "Users",
    to: "/dashboard/users",
    roles: ["Admin", "ClientAdmin"],
  },
  {
    // icon: 'mdi-file',
    title: "Clients",
    to: "/dashboard/clients",
    roles: ["Admin"],
  },
  {
    // icon: 'mdi-file',
    title: "Passengers",
    to: "/dashboard/patients",
    roles: ["Admin", "ClientAdmin", "ClientUser"],
  },
  {
    // icon: 'mdi-file',
    title: "Client Destinations",
    to: "/dashboard/destinations",
    roles: ["Admin", "ClientAdmin", "ClientUser"],
  },
  {
    // icon: 'mdi-file',
    title: "Calendar",
    to: "/dashboard/calendar",
    roles: ["Admin", "ClientAdmin", "ClientUser", "Driver"],
  },
  {
    // icon: 'mdi-file',
    title: "Orders",
    to: "/dashboard/orders",
    roles: ["Admin", "ClientAdmin", "ClientUser", "Driver"],
  },
  {
    // icon: 'mdi-file',
    title: "Chat",
    to: "/dashboard/chat",
    roles: ["Admin", "ClientAdmin", "ClientUser", "Driver"],
  },
  {
    // icon: 'mdi-file',
    title: "Notifications",
    to: "/dashboard/notifications",
    roles: ["Admin", "ClientAdmin", "ClientUser", "Driver"],
  },
  {
    // icon: 'mdi-file',
    title: "Payroll",
    to: "/dashboard/payroll",
    roles: ["Admin"],
  },
  {
    // icon: 'mdi-file',
    title: "Invoices",
    to: "/dashboard/accounting",
    roles: ["ClientAdmin", "Driver"],
  },
  {
    // icon: 'mdi-file',
    title: "Settings",
    to: "/dashboard/settings",
    roles: ["Admin"],
  },
];
